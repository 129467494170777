<template>
  <button>
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.9585 2.31663C21.9067 3.44149 23.5273 5.05564 24.6599 6.99931C25.7926 8.94298 26.3979 11.1488 26.4161 13.3983C26.4343 15.6478 25.8646 17.8631 24.7635 19.8248C23.6624 21.7865 22.0681 23.4266 20.1383 24.5828C18.2086 25.739 16.0103 26.3711 13.7612 26.4166C11.5121 26.4622 9.29004 25.9195 7.31509 24.8423C5.34014 23.7652 3.68075 22.1909 2.50119 20.2753C1.32163 18.3598 0.662816 16.1694 0.589955 13.921L0.583496 13.5025L0.589955 13.084C0.662292 10.8532 1.31141 8.67933 2.47403 6.77417C3.63665 4.869 5.27309 3.29759 7.22381 2.21315C9.17454 1.1287 11.373 0.568228 13.6048 0.586364C15.8366 0.6045 18.0257 1.20063 19.9585 2.31663ZM13.5002 12.2108H12.2085L12.0574 12.2198C11.7305 12.2587 11.4307 12.421 11.2194 12.6734C11.0081 12.9259 10.9012 13.2495 10.9205 13.5782C10.9398 13.9068 11.0838 14.2157 11.3232 14.4417C11.5626 14.6677 11.8793 14.7938 12.2085 14.7941V18.6691L12.2175 18.8203C12.2546 19.1345 12.4056 19.4241 12.642 19.6344C12.8784 19.8446 13.1838 19.9608 13.5002 19.9608H14.7918L14.943 19.9518C15.2572 19.9147 15.5468 19.7637 15.7571 19.5273C15.9673 19.2909 16.0835 18.9855 16.0835 18.6691L16.0745 18.518C16.0405 18.2297 15.9103 17.9612 15.705 17.7559C15.4997 17.5507 15.2313 17.4205 14.943 17.3865L14.7918 17.3775V13.5025L14.7828 13.3513C14.7458 13.0371 14.5947 12.7475 14.3583 12.5372C14.1219 12.327 13.8165 12.2108 13.5002 12.2108ZM13.5131 7.04413L13.349 7.05317C13.0221 7.09206 12.7224 7.25431 12.5111 7.50677C12.2998 7.75923 12.1929 8.08284 12.2121 8.4115C12.2314 8.74015 12.3755 9.04904 12.6149 9.27504C12.8543 9.50104 13.1709 9.6271 13.5002 9.62747L13.6642 9.61842C13.9911 9.57954 14.2908 9.41729 14.5022 9.16483C14.7135 8.91237 14.8204 8.58875 14.8011 8.2601C14.7818 7.93145 14.6377 7.62256 14.3983 7.39656C14.159 7.17056 13.8423 7.0445 13.5131 7.04413Z" fill="#FAFAFA"/>
    </svg>
  </button>
</template>

<style lang="less" scoped>
button {
  margin-top: 8px;
}
</style>