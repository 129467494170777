// stores/counter.js
import { defineStore } from "pinia";

export const useProfilData = defineStore("profilData", {
	state: () => {
		return {
			rekiums: 0,
			subscription: "Free",
			rerenderFlag: false,
		};
	},
	getters: {
		getRekiums: (state) => state.rekiums,
		getSubscription: (state) => state.subscription,
	},
	actions: {
		forceRerender(load: number) {
			this.rerenderFlag = !this.rerenderFlag; 
		  },
		getPoints(){
			return this.rekiums;
		}
	}
});
