// my-components.ts
import { defineComponent, h, computed, ref} from 'vue';
import svgSpinner from '@/assets/icons/spinnerDots.vue';

const GetSlotContent = (context: any) => {
  // Access the slotted content as an array of VNodes
  const slotContent = context.slots.default && context.slots.default();
  return slotContent;
}

export const SubmitButton = defineComponent({
  name: 'SubmitButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, context) {
    const isButtonDisabled = computed(() => props.disabled);
    const isLoading = computed(() => props.loading);

    return () => h('button', { type: 'submit', disabled: isButtonDisabled.value }, [
      isLoading.value ? h(svgSpinner) : GetSlotContent(context),
    ]);
  },
});

export const SlideCheck = defineComponent({
  name: 'SlideCheck',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isChecked = ref(props.checked);

    const toggleChecked = () => {
      isChecked.value = !isChecked.value;
      emit('update:checked', isChecked.value);
    };

    return () =>
      h('div', {
        class: ['slide-check', isChecked.value ? 'checked' : ''],
        onClick: toggleChecked,
      }, [h('div', { class: 'slide-check__circle' })]);
  },
});

export const MyComponent2 = defineComponent({
  name: 'MyComponent2',
  setup() {
    // Your component logic here
    return () => h('div', 'This is My Component 2');
  },
});