<template>
	<RouterLink class="logo" to="/"><img alt="oona logo" src="@/assets/logo.svg" /></RouterLink>
</template>

<style lang="less" scoped>
.logo {
	position: fixed !important;
	left: 21px;
	top: 40px;
	width: 101px;
	height: 24px;
}
</style>
