import {
	toast,
	Slide,
	type CSSTransitionProps,
	type ToastOptions,
} from "vue3-toastify";
import { Icon as ToastSuccess } from "@/assets/icons/toastSuccess";
import { Icon as ToastWarning } from "@/assets/icons/toastWarning";
import { Icon as ToastError } from "@/assets/icons/toastError";

const customAnimation: CSSTransitionProps = {
	...Slide,
	collapseDuration: 150,
};

const AppToast = {
	success: (message: string, options?: ToastOptions) => {
		toast.success(message, {
			...options,
			transition: customAnimation,
			icon: ToastSuccess,
			autoClose: 5000,
		});
	},
	warning: (message: string, options?: ToastOptions) => {
		toast.warning(message, {
			...options,
			transition: customAnimation,
			icon: ToastWarning,
			autoClose: 5000,
		});
	},
	error: (message: string, options?: ToastOptions) => {
		toast.error(message, {
			...options,
			transition: customAnimation,
			icon: ToastError,
			autoClose: 5000,
		});
	},
};

export default AppToast;
