<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_513_6000)">
      <path d="M12 5V19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18 11L12 5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6 11L12 5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
  </svg>
</template>
